<template>
  <div>
    <v-dialog v-model="deleteAccountDialog" width="900" style="z-index: 9999">
      <v-card
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100px;
        "
      >
        <v-col cols="6">
          <v-autocomplete
            v-model="selectedDeleteOption"
            :items="deleteQuestions"
            item-text="text"
            item-value="value"
            hide-details
            filled
            placeholder="İptal Nedeni"
            :filter="false"
            :menu-props="{
              bottom: true,
              offsetY: true,
            }"
          />
        </v-col>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize text-body-1 font-weight-bold px-9"
            rounded
            color="primary"
            depressed
            @click="deleteAccountDialog = false"
            >{{ $t("give_up") }}</v-btn
          >
          <v-btn
            class="text-capitalize text-body-1 font-weight-bold px-9"
            rounded
            depressed
            @click="deleteAccountProcess(userModalAddUpdate.form._id)"
            >{{ $t("apply") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col col="12">
        <v-card elevation="10">
          <v-toolbar
            dense
            color="#003d6a"
            elevation="0"
            dark
            @click="searchBidCollapsed = !searchBidCollapsed"
            style="z-index: 9"
          >
            <v-toolbar-title
              >Üyeler<font-awesome-icon
                class="ml-2"
                :icon="[
                  'fas',
                  searchBidCollapsed ? 'chevron-up' : 'chevron-down',
                ]"
            /></v-toolbar-title>
            <v-spacer></v-spacer>

            <div class="d-flex justify-content-end" style="min-width: 550px">
              <input
                type="text"
                v-model="searchFilterInput"
                class="form-control mr-3"
                style="max-width: 250px"
                placeholder="Arama..."
                v-on:keyup="onFilterTextBoxChanged()"
              />
              <RoleProvider slug="USER_CREATE">
                <div slot-scope="{ canItPass }">
                  <CButton
                    v-if="canItPass"
                    color="light"
                    variant="outline"
                    size="sm"
                    @click="openUserModalAddUpdateForm('post', 'Üye Ekle')"
                    class="py-2 px-3 font-weight-bold"
                  >
                    <font-awesome-icon
                      :icon="['fas', 'plus']"
                      class="mr-2"
                    />Yeni Ekle
                  </CButton>
                </div>
              </RoleProvider>
            </div>
          </v-toolbar>

          <CCollapse :show="searchBidCollapsed">
            <CForm @submit.prevent="getUsers">
              <v-card class="pa-3" elevation="10">
                <v-row>
                  <v-col md="4" cols="12">
                    <v-autocomplete
                      outlined
                      dense
                      hide-details
                      multiple
                      small-chips
                      deletable-chips
                      chips
                      clearable
                      v-model="searchUsersForm.status"
                      :items="searchStatusOptions"
                      item-text="tr"
                      item-value="val"
                      :label="'Üye Durumu'"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col class="mt-1 mt-md-0" md="4" cols="6">
                    <v-text-field
                      outlined
                      dense
                      v-model="searchUsersForm.name"
                      hide-details
                      :label="$t('name')"
                      type="text"
                    />
                  </v-col>
                  <v-col class="mt-1 mt-md-0" md="4" cols="6">
                    <v-text-field
                      outlined
                      dense
                      v-model="searchUsersForm.lastname"
                      hide-details
                      :label="$t('lastname')"
                      type="text"
                    />
                  </v-col>
                  <v-col class="mt-1 mt-md-0" md="4" cols="12">
                    <v-text-field
                      outlined
                      dense
                      v-model="searchUsersForm.identityId"
                      hide-details
                      :label="$t('tc_or_psp')"
                      type="text"
                    />
                  </v-col>

                  <v-col class="mt-1 mt-md-0" md="4" cols="6">
                    <v-text-field
                      outlined
                      dense
                      hide-details
                      v-model="searchUsersForm.phone"
                      :label="$t('phone')"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col class="mt-1 mt-md-0" md="4" cols="6">
                    <v-text-field
                      outlined
                      dense
                      hide-details
                      v-model="searchUsersForm.email"
                      :label="$t('email')"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col class="mt-1 mt-md-0" md="4" cols="6">
                    <v-menu
                      v-model="datePickerBirthdayDateRange"
                      max-width="290"
                      :close-on-content-click="false"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :value="searchUsersForm.birthday"
                          clearable
                          readonly
                          :placeholder="$t('date_of_birth')"
                          dense
                          :label="$t('date_of_birth')"
                          hide-details
                          outlined
                          v-bind="attrs"
                          class="bg-white text-black"
                          v-on="on"
                          @click:clear="searchUsersForm.birthday = []"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        range
                        v-model="searchUsersForm.birthday"
                        @change="datePickerBirthdayDateRange = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col class="mt-1 mt-md-0" md="4" cols="6">
                    <v-menu
                      v-model="datePickerCreatedDateRange"
                      :close-on-content-click="false"
                      max-width="290"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :value="searchUsersForm.createdDate"
                          clearable
                          readonly
                          :placeholder="'Üyelik Tarihi'"
                          dense
                          :label="'Üyelik Tarihi'"
                          hide-details
                          outlined
                          v-bind="attrs"
                          class="bg-white text-black"
                          v-on="on"
                          @click:clear="searchUsersForm.createdDate = []"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        range
                        v-model="searchUsersForm.createdDate"
                        @change="datePickerCreatedDateRange = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col class="mt-1 mt-md-0" md="4" cols="12">
                    <v-autocomplete
                      outlined
                      dense
                      hide-details
                      clearable
                      v-model="searchUsersForm.permissions"
                      :items="permissionOptions"
                      item-text="tr"
                      item-value="val"
                      :label="$t('permissions')"
                      multiple
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="10">
                    <v-btn type="submit" block color="primary">
                      <strong> ARA </strong>
                      <v-icon right dark> mdi-magnify </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn type="button" block @click="clearSearchForm">
                      <strong> TEMİZLE </strong>
                      <v-icon right dark> mdi-delete </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </CForm>
          </CCollapse>

          <CCardBody class="p-0" v-if="usersGridShow">
            <!-- :sendToClipboard="sendToClipboard" -->
            <ag-grid-vue
              style="width: 100%; height: calc(100vh - 145px)"
              class="ag-theme-balham fp-table"
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              :rowData="rowData"
              :defaultExcelExportParams="defaultExcelExportParams"
              :sideBar="sideBar"
              :rowSelection="rowSelection"
              :enableRangeSelection="true"
              :statusBar="statusBar"
              @rowDoubleClicked="onRowDoubleClicked"
              @grid-ready="onGridReady"
            ></ag-grid-vue>
            <!-- :getContextMenuItems="getGridContextMenuItems" -->
          </CCardBody>
        </v-card>
      </v-col>
    </v-row>
    <CModal
      size="lg"
      :closeOnBackdrop="false"
      :show.sync="userModalAddUpdate.modal"
    >
      <template #header class="w-100">
        <div style="display: flex; flex-direction: column; width: 100%">
          <h1>{{ userModalAddUpdate.title }}</h1>
          <button
            type="button"
            aria-label="Close"
            class="close mb-1"
            @click="userModalAddUpdate.modal = false"
          >
            ×
          </button>
          <div
            v-if="userModalAddUpdate.process == 'put'"
            class="d-flex justify-content-between"
            style="width: 100%"
          >
            <div class="d-flex w-100">
              <RoleProvider slug="ADMIN_NOTE_FIND">
                <div slot-scope="{ canItPass }" class="w-100">
                  <AgentNote
                    :_id="userModalAddUpdate.form._id"
                    :slug="'members'"
                    :total="agentNotesTotal"
                    v-if="canItPass"
                  />
                </div>
              </RoleProvider>
            </div>
          </div>
        </div>
      </template>
      <CCard>
        <CCardHeader component="h5" class="d-flex justify-content-between">
          <div class="font-weight-bold">Üye Bilgileri</div>
        </CCardHeader>
        <CCardBody class="card-body-color">
          <CCardText>
            <CRow>
              <CCol md="6">
                <validation-provider
                  mode="eager"
                  name="Ad"
                  rules="required|alpha_spaces|max:500"
                >
                  <CInput
                    label="Ad"
                    class="font-weight-bold"
                    required
                    @input="
                      userModalAddUpdate.form.name =
                        userModalAddUpdate.form.name.turkishToUpper()
                    "
                    v-model="userModalAddUpdate.form.name"
                  />
                </validation-provider>
              </CCol>
              <CCol md="6">
                <validation-provider
                  mode="eager"
                  name="Soyad"
                  rules="required|alpha_spaces|max:500"
                >
                  <CInput
                    label="Soyad"
                    class="font-weight-bold"
                    required
                    @input="
                      userModalAddUpdate.form.lastname =
                        userModalAddUpdate.form.lastname.turkishToUpper()
                    "
                    v-model="userModalAddUpdate.form.lastname"
                  />
                </validation-provider>
              </CCol>
              <CCol md="6">
                <validation-provider
                  mode="eager"
                  name="E-Posta"
                  rules="required|email"
                >
                  <CInput
                    label="E-Posta"
                    class="font-weight-bold"
                    :disabled="userModalAddUpdate.process == 'put'"
                    v-model="userModalAddUpdate.form.email"
                  />
                </validation-provider>
              </CCol>
              <CCol md="6">
                <validation-provider
                  mode="eager"
                  name="Cep Telefonu"
                  rules="required|length:10"
                >
                  <label class="font-weight-bold" v-html="'Cep Telefonu'" />

                  <vue-tel-input
                    @input="onInput"
                    :disabled="userModalAddUpdate.process == 'put'"
                    :inputOptions="{ placeholder: '' }"
                    defaultCountry="tr"
                    mode="international"
                    v-model="userModalAddUpdate.form.phone"
                  ></vue-tel-input>
                </validation-provider>
              </CCol>
              <CCol md="6">
                <label class="font-weight-bold" v-html="'Doğum Tarihi'" />
                <imask-input
                  class="form-control"
                  v-model="userModalAddUpdate.form.birthday"
                  :mask="Date"
                  :lazy="false"
                  radix="/"
                  :unmask="false"
                  placeholder="Örn: 01.01.1990"
                />
              </CCol>
              <CCol md="6">
                <CInputRadio
                  v-for="(option, optKey) in userModalAddUpdate.fields
                    .defaultIdType.options"
                  :key="option + optKey"
                  name="defaultIdType"
                  :inline="true"
                  :label="option.label"
                  :value="option.value"
                  :checked="
                    userModalAddUpdate.form.defaultIdType === option.value
                  "
                  @update:checked="
                    () => (userModalAddUpdate.form.defaultIdType = option.value)
                  "
                />

                <CInput
                  v-if="userModalAddUpdate.form.defaultIdType == 0"
                  type="text"
                  v-model="userModalAddUpdate.form.tcId"
                  :maxlength="11"
                  :class="{
                    'mt-2 font-weight-bold': true,
                    shake: userModalAddUpdate.fields.tcId.options.shake,
                  }"
                />

                <CInput
                  v-if="userModalAddUpdate.form.defaultIdType == 1"
                  type="text"
                  v-model="userModalAddUpdate.form.passportId"
                  :class="{
                    'mt-2 font-weight-bold': true,
                    shake: userModalAddUpdate.fields.passportId.options.shake,
                  }"
                />
              </CCol>
            </CRow>
          </CCardText>
        </CCardBody>
      </CCard>
      <CRow>
        <CCol col="12">
          <CCard>
            <CCardHeader component="h5">
              <div class="font-weight-bold">Bizi Nereden Duydunuz</div>
            </CCardHeader>
            <CCardBody class="card-body-color">
              <CCardText>
                <CRow>
                  <CCol col="5">
                    <v-select
                      :clearable="false"
                      v-model="userModalAddUpdate.form.whereDidYouHear"
                      :reduce="(item) => item"
                      :options="whereDidYouHearJSON"
                      placeholder="Bizi Nereden Duydunuz"
                      label="name"
                      id="id"
                  /></CCol>
                </CRow>
              </CCardText>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow v-if="userModalAddUpdate.process == 'put'">
        <CCol col="12">
          <CCard>
            <CCardHeader component="h5">
              <div class="font-weight-bold">Kuponları</div>
            </CCardHeader>
            <CCardBody class="card-body-color">
              <CCardText>
                <CRow>
                  <CCol col="5">
                    <v-select
                      :clearable="false"
                      v-model="couponsName"
                      :reduce="(item) => item"
                      :options="couponsJSON"
                      placeholder="Seçiniz"
                      label="name"
                      id="_id"
                  /></CCol>
                  <CCol col="5">
                    <CInput
                      placeholder="Kupon Adet"
                      v-model.number="couponsNumber"
                    ></CInput>
                  </CCol>
                  <CCol col="2">
                    <CButton
                      color="info text-white font-weight-bold "
                      block
                      @click="couponsUserAdd"
                      >Oluştur</CButton
                    >
                  </CCol>
                </CRow>
                <div v-if="couponsData.length > 0">
                  <CRow class="my-3">
                    <CCol col="4" class="font-weight-bold"> Kupon Adı </CCol>
                    <CCol col="3" class="font-weight-bold"> Kodu </CCol>
                    <CCol col="3" class="font-weight-bold">
                      Kalan Kullanım Hakkı
                    </CCol>
                    <CCol col="3" class="font-weight-bold"> Güncelleme </CCol>
                    <CCol col="2"> </CCol>
                  </CRow>
                  <CRow
                    v-for="data in couponsData"
                    :key="data._id"
                    class="my-3"
                  >
                    <CCol col="4">
                      {{ data.couponId.name }}
                    </CCol>
                    <CCol col="3">
                      {{ data.code }}
                    </CCol>
                    <CCol col="3"> {{ data.remaining }} Adet </CCol>
                    <CCol col="3">
                      {{ data.updatedAt || "" }}
                    </CCol>
                    <CCol col="2">
                      <CButton
                        color="danger text-white font-weight-bold "
                        block
                        @click="couponsUserDel(data.code, data.couponId._id)"
                        >Sil</CButton
                      >
                    </CCol>
                  </CRow>
                </div>
              </CCardText>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow>
        <CCol md="6">
          <CCard>
            <CCardHeader component="h5" class="d-flex justify-content-between">
              <div class="font-weight-bold">Fatura Tipi</div>

              <div>
                <CSelect
                  :value.sync="userModalAddUpdate.form.invoices.invoiceType"
                  :options="invoiceTypes"
                />
              </div>
            </CCardHeader>
            <CCardBody class="card-body-color">
              <CCardText>
                <CRow
                  v-if="
                    userModalAddUpdate.form.invoices.invoiceType ===
                    'individual'
                  "
                >
                  <CCol col="6" class="mt-3 pr-0">
                    <validation-provider
                      mode="eager"
                      name="İl"
                      rules="required"
                    >
                      <CInput
                        label="İl"
                        class="font-weight-bold"
                        v-model="
                          userModalAddUpdate.form.invoices.individual.province
                        "
                      />
                    </validation-provider>
                  </CCol>
                  <CCol col="6" class="mt-3 px-1">
                    <validation-provider
                      mode="eager"
                      name="Posta Kodu"
                      rules="required"
                    >
                      <CInput
                        label="Posta Kodu"
                        class="font-weight-bold"
                        v-model="
                          userModalAddUpdate.form.invoices.individual.postCode
                        "
                      />
                    </validation-provider>
                  </CCol>

                  <CCol col="12" lg="12">
                    <validation-provider
                      mode="eager"
                      name="Adres"
                      rules="required"
                    >
                      <CTextarea
                        label="Adres"
                        :rows="userModalAddUpdate.process === 'put' ? 4 : 4"
                        class="font-weight-bold"
                        v-model="
                          userModalAddUpdate.form.invoices.individual.address
                        "
                      />
                    </validation-provider>
                  </CCol>
                </CRow>

                <CRow
                  v-if="
                    userModalAddUpdate.form.invoices.invoiceType ===
                    'individualCompany'
                  "
                >
                  <CCol col="6" class="mt-3 pr-0">
                    <validation-provider
                      mode="eager"
                      name="Ad"
                      rules="required"
                    >
                      <CInput
                        label="Ad"
                        class="font-weight-bold"
                        v-model="
                          userModalAddUpdate.form.invoices.individualCompany
                            .firstname
                        "
                      />
                    </validation-provider>
                  </CCol>
                  <CCol col="6" class="mt-3 pr-0">
                    <validation-provider
                      mode="eager"
                      name="Soyad"
                      rules="required"
                    >
                      <CInput
                        label="Soyad"
                        class="font-weight-bold"
                        v-model="
                          userModalAddUpdate.form.invoices.individualCompany
                            .lastname
                        "
                    /></validation-provider>
                  </CCol>
                  <CCol col="6" class="mt-3 pr-0">
                    <validation-provider
                      mode="eager"
                      name="Vergi Dairesi"
                      rules="required"
                    >
                      <CInput
                        label="Vergi Dairesi"
                        class="font-weight-bold"
                        v-model="
                          userModalAddUpdate.form.invoices.individualCompany
                            .taxOffice
                        "
                    /></validation-provider>
                  </CCol>
                  <CCol col="6" class="mt-3 pr-0">
                    <validation-provider
                      mode="eager"
                      name="Vergi / TC No"
                      rules="required"
                    >
                      <CInput
                        label="Vergi / TC No"
                        class="font-weight-bold"
                        v-model="
                          userModalAddUpdate.form.invoices.individualCompany
                            .taxNumber
                        "
                    /></validation-provider>
                  </CCol>
                  <CCol col="6" class="mt-3 pr-0">
                    <validation-provider
                      mode="eager"
                      name="İl"
                      rules="required"
                    >
                      <CInput
                        label="İl"
                        class="font-weight-bold"
                        v-model="
                          userModalAddUpdate.form.invoices.individualCompany
                            .province
                        "
                    /></validation-provider>
                  </CCol>
                  <CCol col="6" class="mt-3 px-1">
                    <validation-provider
                      mode="eager"
                      name="Posta Kodu"
                      rules="required"
                    >
                      <CInput
                        label="Posta Kodu"
                        class="font-weight-bold"
                        v-model="
                          userModalAddUpdate.form.invoices.individualCompany
                            .postCode
                        "
                    /></validation-provider>
                  </CCol>

                  <CCol col="12" lg="12">
                    <validation-provider
                      mode="eager"
                      name="Adres"
                      rules="required"
                    >
                      <CTextarea
                        label="Adres"
                        :rows="userModalAddUpdate.process === 'put' ? 4 : 4"
                        class="font-weight-bold"
                        v-model="
                          userModalAddUpdate.form.invoices.individualCompany
                            .address
                        "
                    /></validation-provider>
                  </CCol>
                </CRow>

                <CRow
                  v-if="
                    userModalAddUpdate.form.invoices.invoiceType === 'company'
                  "
                >
                  <CCol col="12" class="mt-3 pr-0">
                    <validation-provider
                      mode="eager"
                      name="Firma Ünvanı"
                      rules="required"
                    >
                      <CInput
                        label="Firma Ünvanı"
                        class="font-weight-bold"
                        v-model="
                          userModalAddUpdate.form.invoices.company.title
                        "
                    /></validation-provider>
                  </CCol>

                  <CCol col="6" class="mt-3 pr-0">
                    <validation-provider
                      mode="eager"
                      name="Vergi Dairesi"
                      rules="required"
                    >
                      <CInput
                        label="Vergi Dairesi"
                        class="font-weight-bold"
                        v-model="
                          userModalAddUpdate.form.invoices.company.taxOffice
                        "
                    /></validation-provider>
                  </CCol>
                  <CCol col="6" class="mt-3 pr-0">
                    <validation-provider
                      mode="eager"
                      name="Vergi Numarası"
                      rules="required"
                    >
                      <CInput
                        min="10"
                        max="11"
                        v-mask="'###########'"
                        label="Vergi Numarası"
                        class="font-weight-bold"
                        v-model="
                          userModalAddUpdate.form.invoices.company.taxNumber
                        "
                    /></validation-provider>
                  </CCol>
                  <CCol col="6" class="mt-3 pr-0">
                    <validation-provider
                      mode="eager"
                      name="İl"
                      rules="required"
                    >
                      <CInput
                        label="İl"
                        class="font-weight-bold"
                        v-model="
                          userModalAddUpdate.form.invoices.company.province
                        "
                    /></validation-provider>
                  </CCol>
                  <CCol col="6" class="mt-3 px-1">
                    <validation-provider
                      mode="eager"
                      name="Posta Kodu"
                      rules="required"
                    >
                      <CInput
                        label="Posta Kodu"
                        class="font-weight-bold"
                        v-model="
                          userModalAddUpdate.form.invoices.company.postCode
                        "
                    /></validation-provider>
                  </CCol>

                  <CCol col="12" lg="12">
                    <validation-provider
                      mode="eager"
                      name="Adres"
                      rules="required"
                    >
                      <CTextarea
                        label="Adres"
                        :rows="userModalAddUpdate.process === 'put' ? 4 : 4"
                        class="font-weight-bold"
                        v-model="
                          userModalAddUpdate.form.invoices.company.address
                        "
                    /></validation-provider>
                  </CCol>
                </CRow>

                <CRow
                  v-if="
                    userModalAddUpdate.form.invoices.invoiceType === 'abroad'
                  "
                >
                  <CCol col="12" class="mt-3 pr-0">
                    <validation-provider
                      mode="eager"
                      name="Firma Ünvanı"
                      rules="required"
                    >
                      <CInput
                        label="Firma Ünvanı"
                        class="font-weight-bold"
                        v-model="userModalAddUpdate.form.invoices.abroad.title"
                    /></validation-provider>
                  </CCol>

                  <CCol col="6" class="mt-3 pr-0">
                    <validation-provider
                      mode="eager"
                      name="Ad"
                      rules="required"
                    >
                      <CInput
                        label="Ad"
                        class="font-weight-bold"
                        v-model="
                          userModalAddUpdate.form.invoices.abroad.firstname
                        "
                    /></validation-provider>
                  </CCol>
                  <CCol col="6" class="mt-3 pr-0">
                    <validation-provider
                      mode="eager"
                      name="Soyad"
                      rules="required"
                    >
                      <CInput
                        label="Soyad"
                        class="font-weight-bold"
                        v-model="
                          userModalAddUpdate.form.invoices.abroad.lastname
                        "
                    /></validation-provider>
                  </CCol>
                  <CCol col="6" class="mt-3 pr-0">
                    <validation-provider
                      mode="eager"
                      name="Pasaport No"
                      rules="required"
                    >
                      <CInput
                        label="Pasaport No"
                        class="font-weight-bold"
                        v-model="
                          userModalAddUpdate.form.invoices.abroad.passportNumber
                        "
                    /></validation-provider>
                  </CCol>
                  <CCol col="6" class="mt-3 pr-0">
                    <validation-provider
                      mode="eager"
                      name="Vergi Numarası"
                      rules="required"
                    >
                      <CInput
                        min="10"
                        max="11"
                        v-mask="'###########'"
                        label="Vergi Numarası"
                        class="font-weight-bold"
                        v-model="
                          userModalAddUpdate.form.invoices.abroad.taxNumber
                        "
                      />
                    </validation-provider>
                  </CCol>
                  <CCol col="4" class="mt-3 pr-0">
                    <validation-provider
                      mode="eager"
                      name="Ülke"
                      rules="required"
                    >
                      <CInput
                        label="Ülke"
                        class="font-weight-bold"
                        v-model="
                          userModalAddUpdate.form.invoices.abroad.country
                        "
                    /></validation-provider>
                  </CCol>
                  <CCol col="4" class="mt-3 pr-0">
                    <validation-provider
                      mode="eager"
                      name="İl"
                      rules="required"
                    >
                      <CInput
                        label="İl"
                        class="font-weight-bold"
                        v-model="
                          userModalAddUpdate.form.invoices.abroad.province
                        "
                    /></validation-provider>
                  </CCol>
                  <CCol col="4" class="mt-3 px-1">
                    <validation-provider
                      mode="eager"
                      name="Posta Kodu"
                      rules="required"
                    >
                      <CInput
                        label="Posta Kodu"
                        class="font-weight-bold"
                        v-model="
                          userModalAddUpdate.form.invoices.abroad.postCode
                        "
                      />
                    </validation-provider>
                  </CCol>

                  <CCol col="12" lg="12">
                    <validation-provider
                      mode="eager"
                      name="Adres"
                      rules="required"
                    >
                      <CTextarea
                        label="Adres"
                        :rows="userModalAddUpdate.process === 'put' ? 4 : 4"
                        class="font-weight-bold"
                        v-model="
                          userModalAddUpdate.form.invoices.abroad.address
                        "
                      />
                    </validation-provider>
                  </CCol>
                </CRow>
              </CCardText>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol md="6">
          <CCard>
            <CCardBody class="card-body-color" style="padding-bottom: 9px">
              <CCardText>
                <ul class="pl-3">
                  <li v-if="userModalAddUpdate.process === 'post'">
                    <a
                      href="https://www.renticar.com/sozlesmeler/uyelik-sozlesmesi"
                      target="_blank"
                    >
                      <strong>Üyelik Sözleşmesi</strong>
                    </a>
                  </li>
                  <li class="mt-2" v-if="userModalAddUpdate.process === 'post'">
                    <a
                      href="https://www.renticar.com/sozlesmeler/kvkk"
                      target="_blank"
                    >
                      <strong>K.V.K.K.</strong> Aydınlatma Metni
                    </a>
                  </li>
                  <li class="mt-2" v-if="userModalAddUpdate.process === 'post'">
                    <a
                      href="https://www.renticar.com/sozlesmeler/acik-riza-metni"
                      target="_blank"
                    >
                      Verilerimin işlenmesi için
                      <strong>Açık Rıza Metni</strong>
                    </a>
                  </li>
                  <li class="mt-2">
                    <a
                      href="https://www.renticar.com/sozlesmeler/ticari-ileti"
                      target="_blank"
                    >
                      <del v-if="!userModalAddUpdate.form.consent">
                        Önemli bildirimler ve kampanyalardan haberdar olmak için
                        <strong>Ticari İleti</strong> onayı
                      </del>
                      <ins v-else>
                        Önemli bildirimler ve kampanyalardan haberdar olmak için
                        <strong>Ticari İleti</strong> onayı
                      </ins>
                    </a>
                  </li>
                  <li class="mt-2 list-unstyled">
                    <CInputCheckbox
                      v-for="(option, optKey) in userModalAddUpdate.fields
                        .permissions.options"
                      :key="option + optKey"
                      :inline="true"
                      :label="option.label"
                      :value="option.value"
                      :checked="
                        userModalAddUpdate.form.permissions.some(
                          (item) => item === option.value
                        )
                      "
                      @update:checked="updatePermissionsCheckBox(option.value)"
                    />
                  </li>
                </ul>
              </CCardText>
            </CCardBody>
          </CCard>
          <div v-if="userModalAddUpdate.process === 'put'">
            <RoleProvider slug="USER_UPDATE">
              <div slot-scope="{ canItPass }">
                <CButton
                  v-if="canItPass"
                  color="info text-white font-weight-bold"
                  @click="userEmailReset(userModalAddUpdate.form._id)"
                  block
                  class="mb-2"
                  >E-Posta Adresini Değiştir</CButton
                >
              </div>
            </RoleProvider>
            <RoleProvider slug="USER_FIND">
              <div slot-scope="{ canItPass }">
                <CButton
                  color="info text-white font-weight-bold"
                  @click="redirectUserReservatins(userModalAddUpdate.form._id)"
                  block
                  class="mb-2"
                  v-if="canItPass"
                  >Kullanıcı Rezervasyonlarına Git</CButton
                >
              </div>
            </RoleProvider>

            <RoleProvider slug="USER_UPDATE">
              <div slot-scope="{ canItPass }">
                <CButton
                  v-if="canItPass"
                  color="info text-white font-weight-bold "
                  @click="phoneUpdate.modal = true"
                  block
                  class="mb-2"
                  >Cep Telefonu Numarasını Değiştir</CButton
                >
              </div>
            </RoleProvider>
            <RoleProvider slug="USER_UPDATE">
              <div slot-scope="{ canItPass }">
                <CButton
                  v-if="canItPass"
                  color="primary font-weight-bold"
                  @click="userPasswordReset(userModalAddUpdate.form._id)"
                  block
                  class="mb-2"
                  >Yeni Şifre Oluştur</CButton
                >
              </div>
            </RoleProvider>
            <RoleProvider slug="USER_UPDATE">
              <div slot-scope="{ canItPass }">
                <CButton
                  v-if="userModalAddUpdate.form.status != 'Active' && canItPass"
                  @click="
                    userChangeStatus(userModalAddUpdate.form._id, 'Active')
                  "
                  color="success font-weight-bold"
                  block
                  class="mb-2"
                  >Kullanıcıyı Aktif Yap</CButton
                >
              </div>
            </RoleProvider>
            <RoleProvider slug="USER_UPDATE">
              <div slot-scope="{ canItPass }">
                <CButton
                  v-if="canItPass"
                  @click="
                    (couponModalShow = true)((userModalAddUpdate.modal = false))
                  "
                  color="success font-weight-bold"
                  block
                  class="mb-2"
                  >Kullanıcıya Kupon Ata</CButton
                >
              </div>
            </RoleProvider>
            <RoleProvider slug="USER_UPDATE">
              <div slot-scope="{ canItPass }">
                <CButton
                  v-if="
                    userModalAddUpdate.form.status != 'InActive' && canItPass
                  "
                  @click="
                    userChangeStatus(userModalAddUpdate.form._id, 'InActive')
                  "
                  color="warning text-white font-weight-bold"
                  block
                  class="mb-2"
                  >Kullanıcıyı Pasif Yap</CButton
                >
              </div>
            </RoleProvider>
            <RoleProvider slug="USER_UPDATE">
              <div slot-scope="{ canItPass }">
                <CButton
                  v-if="userModalAddUpdate.form.status != 'Banned' && canItPass"
                  @click="
                    userChangeStatus(userModalAddUpdate.form._id, 'Banned')
                  "
                  color="danger font-weight-bold"
                  block
                  class="mb-2"
                  >Kullanıcıyı Blokla</CButton
                >
              </div>
            </RoleProvider>
            <RoleProvider slug="USER_DELETE">
              <div
                slot-scope="{ canItPass }"
                v-if="
                  $store.state.GetServerData.departmanRole ===
                  'CustomerExperience'
                "
              >
                <CButton
                  v-if="canItPass"
                  color="danger font-weight-bold"
                  @click="userDelete(userModalAddUpdate.form._id)"
                  block
                  class="mb-2"
                  >Kullanıcıyı Sil</CButton
                >
              </div>
            </RoleProvider>
            <RoleProvider slug="USER_DELETE">
              <div slot-scope="{ canItPass }">
                <CButton
                  v-if="canItPass"
                  color="secondary font-weight-bold"
                  @click="deleteAccount()"
                  block
                  class="mb-2"
                  >Kullanıcı Üyeliğini İptal Et</CButton
                >
              </div>
            </RoleProvider>
          </div>
        </CCol>
      </CRow>
      <template #footer>
        <RoleProvider slug="USER_CREATE">
          <div class="w-100" slot-scope="{ canItPass }">
            <CButton
              color="success"
              size="lg"
              block
              v-if="userModalAddUpdate.process == 'post' && canItPass"
              :disabled="userModalAddUpdate.btnDisable"
              @click="userAdd()"
              >Üye Ekle</CButton
            >
          </div>
        </RoleProvider>
        <RoleProvider slug="USER_UPDATE">
          <div class="w-100" slot-scope="{ canItPass }">
            <CButton
              color="success"
              size="lg"
              block
              v-if="userModalAddUpdate.process == 'put' && canItPass"
              :disabled="userModalAddUpdate.btnDisable"
              @click="userUpdate()"
              >Üye Güncelle</CButton
            >
          </div>
        </RoleProvider>
      </template>
    </CModal>
    <CModal
      size="lg"
      title="Kullanıcıya Kupon Ata"
      :show.sync="couponModalShow"
    >
      <template #footer>
        <CButton color="danger">Sil</CButton>
      </template>
    </CModal>
    <CModal
      size="lg"
      title="Yeni Cep Telefon"
      :closeOnBackdrop="false"
      :show.sync="phoneUpdate.modal"
    >
      <vue-tel-input
        @input="onInputNew"
        :inputOptions="{ placeholder: '' }"
        defaultCountry="tr"
        mode="international"
        v-model="phoneUpdate.newPhoneInput"
      ></vue-tel-input>
      <template #footer>
        <CButton
          color="success"
          size="lg"
          @click="userPhoneReset(userModalAddUpdate.form._id)"
          >Değiştir</CButton
        >
      </template>
    </CModal>
  </div>
</template>

<script>
import Vue from "vue";
import { VueTelInput } from "vue-tel-input";
import axios from "axios";
import Func from "../../func";
import { AgGridVue } from "ag-grid-vue";
import VueSweetalert2 from "vue-sweetalert2";
import TelAllCountries from "@/assets/js/all-countries";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker"; //h ttps://chronotruck.github.io/vue-ctk-date-time-picker/
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css"; //https://chronotruck.github.io/vue-ctk-date-time-picker/
import moment from "moment";
import vSelect from "vue-select";
import AgentNote from "@/components/AgentNote";
import { IMaskComponent } from "vue-imask";
import { cibLgtm } from "@coreui/icons";
import { RoleProvider } from "../../provider";
var _ = require("lodash");
Vue.use(VueSweetalert2);
const { VUE_APP_API_URL } = process.env;
// ? Css.
import "vue-select/dist/vue-select.css";
var filterParams = {
  buttons: ["reset"],
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    cellValue = moment(cellValue).startOf("day").toDate();
    cellValue = cellValue.getTime();
    filterLocalDateAtMidnight = filterLocalDateAtMidnight.getTime();
    if (cellValue == null) return 0;

    if (cellValue < filterLocalDateAtMidnight) {
      return -1;
    } else if (cellValue > filterLocalDateAtMidnight) {
      return 1;
    }

    return 0;
  },
  browserDatePicker: true,
  minValidYear: 2000,
  maxValidYear: 2025,
  inRangeFloatingFilterDateFormat: "Do MMM YYYY",
};
export default {
  name: "Users",
  components: {
    AgGridVue,
    vSelect,
    AgentNote,
    VueCtkDateTimePicker,
    "imask-input": IMaskComponent,
    RoleProvider,
    VueTelInput,
  },
  data() {
    return {
      defaultExcelExportParams: null,
      couponsName: {
        name: "Kupon Seçin",
        items: [],
      },
      searchBidCollapsed: true,
      usersGridShow: false,
      couponsNumber: "",
      whereDidYouHearJSON: [
        "Instagram",
        "Twitter",
        "Facebook",
        "Google/Arama Motoru",
        "Youtube",
        "Broşür-Katalog-Insert",
        "Fuar/Etkinlik/Stand Faaliyetinden",
        "Radyo",
        "TV Reklamı",
        "Euro Kanallar / Bant Yayınları",
        "Referans/Tavsiye",
        "Açıkhava Reklamı",
        "Bilinmiyor/Hatırlamıyor",
        "RentiCar Çalışanı",
        "İş Birliği",
      ],
      couponsJSON: [],
      couponsData: [],
      selectedDeleteOption: null,
      deleteAccountDialog: false,
      deleteQuestions: [],
      isPhoneFormat: false,
      phoneUpdate: {
        modal: false,
        newPhone: null,
        newPhoneInput: null,
      },
      couponModalShow: false,
      invoiceTypes: [
        {
          value: "individual",
          label: "Bireysel",
        },
        {
          value: "individualCompany",
          label: "Şahış Şirketi",
        },
        {
          value: "company",
          label: "Kurumsal",
        },
        {
          value: "abroad",
          label: "Yabancı",
        },
      ],
      searchFilterInput: "",
      agentNotesTotal: 0,
      options: [
        {
          value: 0,
          text: "Angular",
        },
        {
          value: 1,
          text: "Bootstrap",
        },
        {
          value: 2,
          text: "React.js",
        },
        {
          value: 3,
          text: "Vue.js",
        },
        {
          label: "backend",
          options: [
            {
              value: 4,
              text: "Django",
            },
            {
              value: 5,
              text: "Laravel",
            },
            {
              value: 6,
              text: "Node.js",
            },
          ],
        },
      ],
      userEmailToken: "",
      userPhoneToken: "",
      // GENEL
      func: Func,
      settings: null,

      // AG GRID
      columnDefs: [
        // Grid sütünların dizisi
        {
          field: "status",
          cellRenderer: (params) => {
            if (params.value === "Active") return "Aktif";
            else if (params.value === "InActive") return "Pasif";
            else if (params.value === "Pending") return "Onay Bekliyor";
            else if (params.value === "Delete") return "Silindi";
            else if (params.value === "Banned") return "Engellendi";
          },
          cellStyle: (params) => {
            if (params.value === "Active")
              return {
                color: "#2eb85c",
                fontWeight: "bold",
              };
            else if (params.value === "InActive")
              return {
                color: "#e55353",
                fontWeight: "bold",
              };
            else if (params.value === "Pending")
              return {
                color: "#f58d42",
                fontWeight: "bold",
              };
            else if (params.value === "Banned")
              return {
                color: "#e55353",
                fontWeight: "bold",
              };
            else if (params.value === "Delete")
              return {
                color: "#e55353",
                fontWeight: "bold",
              };
          },
          headerName: "Durum",
          width: 100,
          suppressSizeToFit: true,
        },

        {
          field: "role",
          width: 120,
          headerName: "Kullanıcı Tipi",
          cellRenderer: (params) => {
            switch (params.value) {
              case "user":
                return "Kayıtlı Üye";
              case "guest":
                return "Misafir Üye";

              default:
                return "Bulunamadı";
            }
          },
        },
        {
          field: "name",
          headerName: "Ad",
        },
        {
          field: "lastname",
          headerName: "Soyad",
        },
        {
          field: "email",
          headerName: "E-Posta",
        },
        {
          field: "phone",
          width: 120,
          headerName: "Telefon",
        },
        {
          field: "birthday",
          width: 120,
          headerName: "Doğum Tarihi",
          filter: "agDateColumnFilter",
          filterParams: filterParams,
          cellRenderer: (params) => {
            if (params.value) return moment(params.value).format("DD.MM.YYYY");
          },
        },
        {
          field: "registered",
          headerName: "Kayıt Tarihi",
          cellRenderer: (params) => {
            return moment(params.value).format("DD.MM.YYYY - HH:mm");
          },
          filter: "agDateColumnFilter",
          filterParams: filterParams,
          width: 160,
        },
        {
          field: "defaultIdType",
          width: 100,
          headerName: "Uyruk",
          cellRenderer: (params) => {
            if (params.value === 0) return "Türk Vatandaşı";
            else return "Yabancı Uyruk";
          },
        },
        {
          field: "defaultIdType",
          headerName: "TCKN / Pasaport",
          width: 100,
          cellRenderer: (params) => {
            const data = params.node.data;
            if (params.value === 0 && data?.tcId)
              return `<b>TC:</b> ${data.tcId}`;
            else if (params.value !== 0 && data?.passportId)
              return `<b>P:</b> ${data?.passportId}`;
            else return "Girilmemiş";
          },
        },
        {
          field: "permissions",
          headerName: "Arama İzni",
          width: 100,
          cellRenderer: (params) => {
            return params.value.includes("phone") ? "ONAY" : "RED";
          },
        },
        {
          field: "permissions",
          headerName: "SMS İzni",
          width: 100,
          cellRenderer: (params) => {
            return params.value.includes("sms") ? "ONAY" : "RED";
          },
        },
        {
          field: "permissions",
          headerName: "Email İzni",
          width: 100,
          cellRenderer: (params) => {
            return params.value.includes("email") ? "ONAY" : "RED";
          },
        },
        {
          field: "creator",
          headerName: "Üye Yapan",
          width: 100,
          cellRenderer: (params) => {
            if (params.value) return "Agent";
            return "User";
          },
        },
        {
          field: "creatorName",
          headerName: "Üyeyi Yapan Kişi",
          width: 125,
        },
        {
          field: "whereDidYouHear",
          width: 120,
          headerName: "Bizi Nereden Duydunuz",
        },
        {
          field: "successRez", //toplam yapılan rezervasyonlar iptaller haric
          width: 120,
          headerName: "Toplam Kiralama Sayısı",
        },
        {
          field: "cancelledRez", //toplam yapılan rezervasyonlar iptaller haric
          width: 120,
          headerName: "İptal İşlem Sayısı",
        },
        {
          field: "totalTransaction", //toplam yapılan işlem sayısı iptaller dahil
          width: 120,
          headerName: "İşlem Toplamı",
        },
        {
          field: "totalReservationPrice",
          width: 120,
          headerName: "Toplam Ödeme",
        },
        {
          field: "totalReservationRefundPrice",
          width: 120,
          headerName: "Toplam İade",
        },
        {
          field: "totalReservationTL",
          width: 120,
          headerName: "Toplam Rezervasyon",
          cellRenderer: (params) => {
            return Func.toMoneyFormat(params.value).toLocaleString("tr-TR", {
              style: "currency",
              currency: this.$store.state.currency,
            });
          },
        },
        {
          field: "preferedLanguage",
          width: 90,
          headerName: "Dil Tercihi",
          valueGetter: (params) => {
            if (params.data?.totalReservation?.length > 0) {
              return params.data?.totalReservation[
                params.data.totalReservation?.length - 1
              ].lang;
            } else {
              return params.data?.preferedLanguage || "TR";
            }
          },
          cellRenderer: (params) => {
            if (
              params.value == "TR" ||
              params.value == "tr" ||
              params.value == "Tr"
            )
              return "TR";
            else if (
              params.value == "EN" ||
              params.value == "en" ||
              params.value == "En"
            )
              return "EN";
            else if (
              params.value == "DE" ||
              params.value == "de" ||
              params.value == "De"
            )
              return "DE";
            else return "TR";
          },
        },
      ],
      defaultColDef: {
        // Varsayılan bir sütun tanımı
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowData: [], // Verilerin ekleneceği dizi
      sideBar: {
        // Grid yan çubuk tanımlaması
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Sütunlar",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          ,
          {
            id: "filters",
            labelDefault: "Filtreler",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
        defaultToolPanel: "",
      },
      rowSelection: "multiple", // Satır Seçim Türü
      statusBar: {
        // Durum çubuğunda kullanılacak bileşenler
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center",
          },
          {
            statusPanel: "agFilteredRowCountComponent",
          },
          {
            statusPanel: "agSelectedRowCountComponent",
          },
          {
            statusPanel: "agAggregationComponent",
          },
        ],
      },

      // USER EKLEME GÜNCELLEME MODAL
      userModalAddUpdate: {
        modal: false,
        title: "",
        process: "",
        btnDisable: false,
        fields: {
          name: {
            options: {
              min: 2,
              max: 75,
              shake: false,
            },
            valid: {
              value: null,
              text: "",
            },
          },
          lastname: {
            options: {
              min: 2,
              max: 50,
              shake: false,
            },
            valid: {
              value: null,
              text: "",
            },
          },
          email: {
            options: {
              min: 5,
              max: 50,
              shake: false,
            },
            valid: {
              value: null,
              text: "",
            },
          },
          phone: {
            options: {
              allCountries: TelAllCountries, //   Type: Array =>          Default: An array of all countries, see allCountries.js => All countries that are used in libphonenumber-js, can be overridden by this prop
              autoFormat: true, //                Type: Boolean =>        Default: true =>        Auto update the input to the formatted phone number when it's valid
              customValidate: false, //           Type: Boolean, RegExp => Default: false =>      Custom validation RegExp for input
              defaultCountry: "tr", //            Type: String, Number => Default: '' =>          Default country (by iso2 or dialCode), will override the country fetched from IP address of user
              disabled: false, //                 Type: Boolean =>        Default: false =>       Disable vue-tel-input, including the input & flag dropdown
              autoDefaultCountry: true, //        Type: Boolean =>        Default: true =>        To fetch default country based on IP address of user
              ignoredCountries: [], //            Type: Array =>          Default: [] =>          List of countries will NOT be shown on the dropdown
              errorMessage: "", //                Type: String =>         Default: ''
              mode: "national", //           Type: String =>         Default: 'auto' =>      Allowed values: 'auto' (Default set by phone), 'international' (Format number with the dial code i.e. + 61), 'national' (Format number without dial code i.e. 0321232)
              onlyCountries: [], //               Type: Array =>          Default: [] =>          List of countries will be shown on the dropdown
              preferredCountries: [], //          Type: Array =>          Default: [] =>          Preferred countries list, will be on top of the dropdown
              styleClasses: "", //                Type: String, Array, Object => Default: '' =>   Custom classes for the wrapper
              validCharactersOnly: true, //       Type: Boolean =>        Default: false =>       Only allow valid characters in a phone number (will also verify in mounted, so phone number with invalid characters will be shown as an empty string)

              label: "Cep Telefonu",
              labelBold: true,
              min: 17,
              max: 17,
              shake: false,
            },
            inputOptions: {
              autocomplete: "on", //              Type: String =>         Default: 'on' =>        Native input autocomplete attribute
              autofocus: false, //                Type: Boolean =>        Default: false =>       Native input autofocus attribute
              "aria-describedby": "", //          Type: String =>         Default: '' =>          Native input aria-describedby attribute
              id: "", //                          Type: String =>         Default: '' =>          Native input id attribute
              maxlength: 17, //                   Type: Number =>         Default: 25 =>          Native input maxlength attribute
              name: "telephone", //               Type: String =>         Default: 'telephone' => Native input name attribute
              placeholder: "", // Type: String => Default: 'Enter a phone number' => Placeholder for the input
              readonly: false, //                 Type: Boolean =>        Default: false =>       Native input readonly attribute
              required: true, //                  Type: Boolean =>        Default: false =>       Native input required attribute
              tabindex: 0, //                     Type: Number =>         Default: 0 =>           Native input tabindex attribute
              type: "tel", //                     Type: String =>         Default: 'tel' =>       Native input type attribute
              styleClasses: "", //                Type: String, Array, Object => Default: '' =>   Custom classes for the input
            },
            dropdownOptions: {
              disabled: false, //                 Type: Boolean =>        Default: false =>       Disable dropdown
              showDialCodeInList: true, //        Type: Boolean =>        Default: true =>        Show dial code in the dropdown list
              showDialCodeInSelection: false, //  Type: Boolean =>        Default: false =>       Show dial code in the dropdown selection
              showFlags: true, //                 Type: Boolean =>        Default: true =>        Show flags in the dropdown selection and list
              tabindex: 0, //                     Type: Number =>         Default: 0 =>           Native dropdown tabindex attribute
            },
            valid: {
              value: null,
              text: "",
            },
          },
          birthday: {
            options: {
              minDate: null,
              maxDate: null,
            },
          },
          defaultIdType: {
            options: [
              {
                value: 0,
                label: "TC Kimlik",
              },
              {
                value: 1,
                label: "Pasaport",
              },
            ],
          },
          tcId: {
            options: {
              min: 11,
              max: 11,
              shake: false,
            },
            valid: {
              value: null,
              text: "",
            },
          },
          passportId: {
            options: {
              min: 7,
              max: 9,
              shake: false,
            },
            valid: {
              value: null,
              text: "",
            },
          },
          password: {
            options: {
              min: 6,
              max: 25,
              shake: false,
            },
            valid: {
              value: null,
              text: "",
            },
          },
          passwordAgain: {
            options: {
              min: 6,
              max: 25,
              shake: false,
            },
            valid: {
              value: null,
              text: "",
            },
          },
          status: {
            options: [
              {
                value: "Active",
                label: "Aktif",
              },
              {
                value: "InActive",
                label: "Pasif",
              },
              {
                value: "Pending",
                label: "Bekliyor",
              },
              {
                value: "Banned",
                label: "Engellendi",
              },
            ],
          },
          lang: {
            options: [
              {
                value: "tr",
                label: "Türkçe",
              },
              {
                value: "en",
                label: "İngilizce",
              },
            ],
          },
          permissions: {
            options: [
              {
                value: "sms",
                label: "SMS",
              },
              {
                value: "phone",
                label: "Telefon",
              },
              {
                value: "email",
                label: "E-Posta",
              },
            ],
          },
        },
        form: {
          _id: "",
          name: "",
          lastname: "",
          email: "",
          phone: "",
          phoneInput: "",
          phone: "",
          birthday: "",
          defaultIdType: 0,
          tcId: "",
          passportId: "",
          permissions: ["sms", "phone", "email"],
          checkboxTI: 1,

          invoices: {
            invoiceType: "individual",
            individual: {
              province: "",
              postCode: "",
              address: "",
            },
            individualCompany: {
              firstname: "",
              lastname: "",
              taxOffice: "",
              taxNumber: "",
              province: "",
              postCode: "",
              address: "",
            },
            company: {
              title: "",
              taxOffice: "",
              taxNumber: "",
              province: "",
              postCode: "",
              address: "",
            },
            abroad: {
              title: "",
              firstname: "",
              lastname: "",
              passportNumber: "",
              taxNumber: "",
              country: "",
              province: "",
              postCode: "",
              address: "",
            },
          },
          whereDidYouHear: "",
        },
      },

      // USER ŞİFRE GÜNCELLEME MODAL
      userModalPasswordUpdate: {
        modal: false,
        title: "",
        btnDisable: false,
        fields: {
          password: {
            options: {
              min: 6,
              max: 25,
              shake: false,
            },
            valid: {
              value: null,
              text: "",
            },
          },
          passwordAgain: {
            options: {
              min: 6,
              max: 25,
              shake: false,
            },
            valid: {
              value: null,
              text: "",
            },
          },
        },
        form: {
          _id: "",
          password: "",
          passwordAgain: "",
        },
      },

      searchStatusOptions: [
        {
          val: "Active",
          tr: "Aktif",
        },
        {
          val: "Pending",
          tr: "Onay Bekleyen",
        },
        {
          val: "InActive",
          tr: "Pasif",
        },
        {
          val: "Banned",
          tr: "Engellenmiş",
        },
        {
          val: "Delete",
          tr: "Silinmiş",
        },
      ],
      permissionOptions: [
        {
          val: "sms",
          tr: "SMS",
        },
        {
          val: "phone",
          tr: "Telefon",
        },
        {
          val: "email",
          tr: "Email",
        },
      ],
      datePickerBirthdayDateRange: false,
      datePickerCreatedDateRange: false,

      searchUsersForm: {
        status: [],
        name: "",
        lastname: "",
        email: "",
        phone: "",
        birthday: [],
        createdDate: [],
        identityId: "",
        permissions: [],
      },
    };
  },
  async created() {
    this.$Progress.start();
    // this.$store.state.isLoading = true;
    // await this.getUsers();
    await this.getSettings();
    this.getcouponsJSON();
    this.defaultExcelExportParams = {
      processCellCallback: (params) => {
        if (params.column.colDef.headerName === "TCKN / Pasaport") {
          const data = params.node.data;
          if (params.value === 0 && data?.tcId) {
            return `TC: ${data.tcId}`;
          } else if (params.value !== 0 && data?.passportId) {
            return `P: ${data?.passportId}`;
          } else {
            return "Girilmemiş";
          }
        } else if (params.column.colDef.headerName === "Arama İzni") {
          return params.value.includes("phone") ? "ONAY" : "RED";
        } else if (params.column.colDef.headerName === "SMS İzni") {
          return params.value.includes("sms") ? "ONAY" : "RED";
        } else if (params.column.colDef.headerName === "Email İzni") {
          return params.value.includes("email") ? "ONAY" : "RED";
        } else if (params.column.colDef.headerName === "Kayıt Tarihi") {
          return moment(params.value).format("DD.MM.YYYY - HH:mm");
        } else if (params.column.colDef.headerName === "Doğum Tarihi") {
          return moment(params.value).format("DD.MM.YYYY - HH:mm");
        } else if (params.column.colDef.headerName === "Üye Yapan") {
          return params.value ? "Agent" : "User";
        } else if (params.column.colDef.headerName === "Uyruk") {
          if (params.value === 0) return "Türk Vatandaşı";
          else return "Yabancı Uyruk";
        } else {
          return params.value;
        }
      },
    };
    this.getDeleteQuestions();
    this.$Progress.finish();
  },
  watch: {
    "userModalAddUpdate.form.permissions": function (value) {
      if (value.length == 0) {
        this.userModalAddUpdate.form.consent = false;
      } else {
        this.userModalAddUpdate.form.consent = true;
      }
    },
    "userModalAddUpdate.form.defaultIdType": function (value) {
      if (value) {
        this.userModalAddUpdate.fields.tcId.valid.value = null;
        this.userModalAddUpdate.fields.tcId.valid.text = "";
        this.userModalAddUpdate.fields.passportId.valid.value = null;
        this.userModalAddUpdate.fields.passportId.valid.text = "";
      }
    },
  },
  methods: {
    redirectUserReservatins(id) {
      window.location.href =
        process.env.VUE_APP_ADMIN_URL + "reservation/index/?" + "userID=" + id;
    },
    async getDeleteQuestions() {
      let result = await axios.get(
        process.env.VUE_APP_API_URL +
          "admin/singular?slug=USER_DELETE_QUESTIONS",
        {
          withCredentials: true,
        }
      );
      let items = result.data.data.items;
      items.filter((item, index) => {
        if (item.status === "active") {
          this.deleteQuestions.push({
            text: this.$i18n.locale === "tr" ? item.name : item.name_en,
            value: {
              tr: item.name,
              en: item.name_en,
            },
          });
        }
      });
    },
    deleteAccount() {
      this.deleteAccountDialog = true;
    },
    async couponsUserAdd() {
      try {
        await axios.put(`${VUE_APP_API_URL}admin/coupon-set-user`, {
          useageCreate: this.couponsNumber,
          userId: this.userModalAddUpdate.form._id,
          couponId: this.couponsName._id,
        });
        this.couponsUserGet(this.userModalAddUpdate.form._id);
        this.couponsName = {
          name: "Kupon Seçin",
          items: [],
        };
        this.couponsNumber = "";
      } catch (error) {
        Vue.swal.fire({
          icon: "error",
          text: error.response.data.message,
          showCloseButton: true,
        });
      }
    },
    async couponsUserDel(code, titleCouponId) {
      Vue.swal
        .fire({
          title: "Uyarı ?",
          text: "Bu kuponu silmek istediğinize emin misiniz?",
          icon: "question",
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonText: "Evet",
          cancelButtonText: "Hayır",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            let params = {
              userId: this.userModalAddUpdate.form._id,
              titleCouponId,
              code,
            };
            try {
              await axios.delete(`${VUE_APP_API_URL}admin/coupon-delete-user`, {
                data: params,
              });
              this.couponsUserGet(this.userModalAddUpdate.form._id);
            } catch (error) {
              Vue.swal.fire({
                icon: "error",
                showCloseButton: true,
                text: error.response.data.message,
              });
            }
          }
        });
    },
    async couponsUserGet(id = this.userModalAddUpdate.form._id) {
      try {
        let { data } = await axios.get(
          `${VUE_APP_API_URL}admin/coupon-get-user?userId=${id}`
        );
        this.couponsData = data;
      } catch (error) {}
    },
    async getcouponsJSON() {
      try {
        let { data } = await axios.get(
          `${VUE_APP_API_URL}admin/coupons?all=true`
        );
        this.couponsJSON = data;
      } catch (error) {}
    },
    onInput(formattedNumber, { number, valid, country }) {
      if (valid === true) {
        this.userModalAddUpdate.form.phone = number;
      }
    },
    onInputNew(formattedNumber, { number, valid, country }) {
      if (valid === true) {
        this.isPhoneFormat = true;
        this.phoneUpdate.newPhone = number;
      } else {
        this.isPhoneFormat = false;
      }
    },
    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(this.searchFilterInput);
    },

    async userEmailReset(id) {
      Vue.swal
        .fire({
          icon: "question",
          text: "E-Posta adresini güncellemek istiyor musunuz?",
          showCancelButton: true,
          confirmButtonText: "Evet",
          cancelButtonText: "Hayır",
          showCloseButton: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            Vue.swal
              .fire({
                icon: "info",
                text: "Yeni E-Posta Adresini yazınız",
                input: "email",
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText: "Gönder",
                showLoaderOnConfirm: true,
                preConfirm: async (email) => {
                  var params = {
                    id,
                    email,
                  };
                  try {
                    // var result = await axios.post(
                    //   process.env.VUE_APP_API_URL +
                    //     "admin/users/email/verify-control-create",
                    //   params,
                    //   {
                    //     withCredentials: true,
                    //   }
                    // );
                    // this.userEmailToken = result.data.token;

                    await this.userEmailSend(id, email);
                  } catch (error) {
                    Vue.swal.fire({
                      icon: "error",
                      showCloseButton: true,
                      text: error.response.data.message,
                    });
                  }
                },
                allowOutsideClick: () => !Vue.swal.isLoading(),
              })
              .then((result) => {
                if (result.isConfirmed) {
                  this.successSwal(
                    "E-Posta adresi başarılı bir şekilde güncellendi"
                  );
                }
              });
          }
        })
        .catch((error) => {
          Vue.swal
            .fire({
              icon: "error",
              text: error.response.data.message || "Bir sorun oluştu",
              showCancelButton: false,
              confirmButtonText: "Yeniden Dene",
              showCloseButton: true,
            })
            .then(async (result) => {
              if (result.isConfirmed) {
                this.userEmailReset(id);
              }
            });
        });
    },

    async userEmailSend(id, email) {
      var params = {
        email,
        oldEmail: this.userModalAddUpdate.form.email,
        userID: id,
      };
      try {
        var result = await axios.post(
          process.env.VUE_APP_API_URL + "admin/users/email/update",
          params,
          {
            withCredentials: true,
          }
        );
        this.successSwal("E-Posta adresi başarılı bir şekilde güncellendi");
      } catch (error) {
        Vue.swal.fire({
          icon: "error",
          showCloseButton: true,
          text: error.response.data.message,
        });
      }
    },

    /**
     * Kullanıcı email değiştirme kod onayı
    userEmailSend(id, email) {
      Vue.swal
        .fire({
          icon: "warning",
          text: "E-posta adresine gönderilen kodu talep ediniz",
          input: "text",
          showCancelButton: false,
          confirmButtonText: "Devam Et",
          showCloseButton: true,
          showLoaderOnConfirm: true,
          preConfirm: async (code) => {
            var params = {
              code,
              token: this.userEmailToken,
            };
            try {
              var result = await axios.post(
                process.env.VUE_APP_API_URL +
                  "admin/users/email/verify-control-check",
                params,
                {
                  withCredentials: true,
                }
              );
              this.userEmailToken = result.data.token;
            } catch (error) {
              Vue.swal
                .fire({
                  icon: "error",
                  text: error.response.data.message,
                  showCancelButton: false,
                  confirmButtonText: "Yeniden Dene",
                  showCloseButton: true,
                })
                .then(async (result) => {
                  if (result.isConfirmed) {
                    this.userEmailSend(id, email);
                  }
                });
            }
          },
          allowOutsideClick: () => !Vue.swal.isLoading(),
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            var params = {
              email,
              oldEmail: this.userModalAddUpdate.form.email,
              token: this.userEmailToken,
            };
            try {
              var result = await axios.post(
                process.env.VUE_APP_API_URL + "admin/users/email/update",
                params,
                {
                  withCredentials: true,
                }
              );
              this.userEmailToken = result.data.token;
              this.successSwal(
                "E-Posta adresi başarılı bir şekilde güncellendi"
              );
            } catch (error) {
              Vue.swal.fire({
                icon: "error",
                showCloseButton: true,
                text: error.response.data.message,
              });
            }
          }
        });
    },
     */

    async userPhoneReset(id) {
      this.phoneUpdate.modal = true;
      var params = {
        phone: this.phoneUpdate.newPhone,
        id,
      };
      try {
        // var result = await axios.post(
        //   process.env.VUE_APP_API_URL +
        //     "admin/users/phone/verify-control-create",
        //   params,
        //   {
        //     withCredentials: true,
        //   }
        // );
        // this.userPhoneToken = result.data.token;
        if (this.isPhoneFormat) {
          await this.userPhoneSend(id, this.phoneUpdate.newPhone);
        } else {
          return error;
        }
      } catch (error) {
        Vue.swal.fire({
          icon: "error",
          showCloseButton: true,
          text: error.response
            ? error.response.data.message
            : "Telefon Numarasını doğru formatta girin",
        });
      }
    },

    async userPhoneSend(id, phone) {
      var params = {
        phone,
        oldPhone: this.userModalAddUpdate.form.phone,
        userID: id,
      };
      try {
        await axios.post(
          process.env.VUE_APP_API_URL + "admin/users/phone/update",
          params,
          {
            withCredentials: true,
          }
        );
        this.successSwal("Cep Telefonu başarılı bir şekilde güncellendi");
      } catch (error) {
        Vue.swal
          .fire({
            icon: "error",
            text: error.response.data.message,
            showCancelButton: false,
            confirmButtonText: "Yeniden Dene",
            showCloseButton: true,
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              this.userPhoneSend();
            }
          });
      }
    },

    /**
     *  Kullanıcı Telefon değiştirme kod onayı
    userPhoneSend() {
      Vue.swal
        .fire({
          icon: "warning",
          text: "Kullanıcının Cep telefonuna gönderilen SMS kodunu talep ediniz",
          input: "text",
          showCloseButton: true,
          showCancelButton: false,
          confirmButtonText: "Gönder",
          showLoaderOnConfirm: true,
          preConfirm: async (code) => {
            var params = {
              code,
              oldPhone: this.userModalAddUpdate.form.phone,
              token: this.userPhoneToken,
            };
            try {
              await axios.post(
                process.env.VUE_APP_API_URL + "admin/users/phone/update",
                params,
                {
                  withCredentials: true,
                }
              );
            } catch (error) {
              Vue.swal
                .fire({
                  icon: "error",
                  text: error.response.data.message,
                  showCancelButton: false,
                  confirmButtonText: "Yeniden Dene",
                  showCloseButton: true,
                })
                .then(async (result) => {
                  if (result.isConfirmed) {
                    this.userPhoneSend();
                  }
                });
            }
          },
          allowOutsideClick: () => !Vue.swal.isLoading(),
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.successSwal("Cep Telefonu başarılı bir şekilde güncellendi");
          }
        });
    },
     */

    userPasswordReset(userID) {
      Vue.swal
        .fire({
          icon: "question",
          text: "Yeni şifre göndermek istiyor musunuz?",
          showCancelButton: true,
          confirmButtonText: "Evet",
          cancelButtonText: "Hayır",
          showCloseButton: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            var params = {
              userID,
            };
            try {
              await axios.post(
                process.env.VUE_APP_API_URL +
                  "admin/users/send-email-lost-password",
                params,
                {
                  withCredentials: true,
                }
              );

              this.successSwal(
                "Yeni şifre oluşturulup kullanıcıya EMAİL olarak gönderildi",
                false
              );
            } catch (error) {
              Vue.swal.fire({
                icon: "error",
                title: "Hata",
                showCloseButton: true,
                text: error.response.data.message,
              });
            }
          }
        });
    },

    userChangeStatus(id, status) {
      Vue.swal
        .fire({
          title: "Uyarı ?",
          text: "Bu statüsünü " + status + " çekmek istediğinize emin misiniz?",
          icon: "question",
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonText: "Evet",
          cancelButtonText: "Hayır",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              var params = {
                id,
                status,
                permissions: [],
              };
              await axios.put(
                process.env.VUE_APP_API_URL + "admin/users/status-update/",
                params,
                {
                  withCredentials: true,
                }
              );
              this.successSwal("Kullanıcının Statüsü değiştirildi");
            } catch (error) {
              Vue.swal.fire({
                icon: "error",
                showCloseButton: true,
                text: error.response.data.message,
              });
            }
          }
        });
    },
    userDelete(params) {
      Vue.swal
        .fire({
          title: "Uyarı ?",
          text: "Bu kullanıcıyı silmek istediğinize emin misiniz?",
          icon: "question",
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonText: "Evet",
          cancelButtonText: "Hayır",
        })
        .then((result) => {
          if (result.isConfirmed) {
            try {
              axios.delete(
                process.env.VUE_APP_API_URL + "admin/users/" + params,
                {
                  withCredentials: true,
                }
              );
              this.successSwal("Kullanıcı silindi");
            } catch (error) {
              Vue.swal.fire({
                icon: "error",
                showCloseButton: true,
                text: error.response.data.message,
              });
            }
          }
        });
    },
    deleteAccountProcess(params) {
      try {
        axios.post(
          process.env.VUE_APP_API_URL + "admin/delete-account/",
          { params: params, deleteReason: this.selectedDeleteOption },
          {
            withCredentials: true,
          }
        );
        this.deleteAccountDialog = false;
        this.successSwal("Kullanıcı Üyelik İptal Talebi Oluşturuldu");
      } catch (error) {
        Vue.swal.fire({
          icon: "error",
          showCloseButton: true,
          text: error.response.data.message,
        });
      }
    },

    onGridReady(params) {
      // Grid ready olduğunda
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      //params.api.sizeColumnsToFit();
    },

    openUserModalAddUpdateForm(operation, title) {
      this.resetUserModalAddUpdateForm();
      this.userModalAddUpdate.process = operation;
      this.userModalAddUpdate.title = title;
      this.userModalAddUpdate.modal = true;
    },

    resetUserModalAddUpdateForm() {
      this.userModalAddUpdate.form._id = "";
      this.userModalAddUpdate.form.name = "";
      this.userModalAddUpdate.form.lastname = "";
      this.userModalAddUpdate.form.email = "";
      this.userModalAddUpdate.form.phone = "";
      this.userModalAddUpdate.form.birthday = "";
      this.userModalAddUpdate.form.defaultIdType = 0;
      this.userModalAddUpdate.form.tcId = "";
      this.userModalAddUpdate.form.passportId = "";
      this.userModalAddUpdate.form.permissions = ["sms", "phone", "email"];
      this.userModalAddUpdate.form.checkboxTI = 1;

      this.userModalAddUpdate.form.invoices.invoiceType = "individual";

      this.userModalAddUpdate.form.invoices.individual.province = "";
      this.userModalAddUpdate.form.invoices.individual.postCode = "";
      this.userModalAddUpdate.form.invoices.individual.address = "";

      this.userModalAddUpdate.form.invoices.individualCompany.firstname = "";
      this.userModalAddUpdate.form.invoices.individualCompany.lastname = "";
      this.userModalAddUpdate.form.invoices.individualCompany.taxOffice = "";
      this.userModalAddUpdate.form.invoices.individualCompany.taxNumber = "";
      this.userModalAddUpdate.form.invoices.individualCompany.province = "";
      this.userModalAddUpdate.form.invoices.individualCompany.postCode = "";
      this.userModalAddUpdate.form.invoices.individualCompany.address = "";

      this.userModalAddUpdate.form.invoices.company.title = "";
      this.userModalAddUpdate.form.invoices.company.taxOffice = "";
      this.userModalAddUpdate.form.invoices.company.taxNumber = "";
      this.userModalAddUpdate.form.invoices.company.province = "";
      this.userModalAddUpdate.form.invoices.company.postCode = "";
      this.userModalAddUpdate.form.invoices.company.address = "";

      this.userModalAddUpdate.form.invoices.abroad.title = "";
      this.userModalAddUpdate.form.invoices.abroad.firstname = "";
      this.userModalAddUpdate.form.invoices.abroad.lastname = "";
      this.userModalAddUpdate.form.invoices.abroad.passportNumber = "";
      this.userModalAddUpdate.form.invoices.abroad.taxNumber = "";
      this.userModalAddUpdate.form.invoices.abroad.country = "";
      this.userModalAddUpdate.form.invoices.abroad.province = "";
      this.userModalAddUpdate.form.invoices.abroad.postCode = "";
      this.userModalAddUpdate.form.invoices.abroad.address = "";
    },

    resetUserModalPasswordUpdateForm() {
      this.userModalPasswordUpdate.fields.password.valid.value = null;
      this.userModalPasswordUpdate.fields.password.valid.text = "";
      this.userModalPasswordUpdate.fields.passwordAgain.valid.value = null;
      this.userModalPasswordUpdate.fields.passwordAgain.valid.text = "";

      this.userModalPasswordUpdate.form.password = "";
      this.userModalPasswordUpdate.form.passwordAgain = "";
    },

    updatePermissionsCheckBox(value) {
      if (
        this.userModalAddUpdate.form.permissions.some((item) => item === value)
      ) {
        let index = this.userModalAddUpdate.form.permissions.findIndex(
          (item) => item === value
        );

        if (index !== -1) {
          this.userModalAddUpdate.form.permissions =
            this.userModalAddUpdate.form.permissions.filter(function (ele) {
              return ele != value;
            });
        }
      } else this.userModalAddUpdate.form.permissions.push(value);
    },

    onRowDoubleClicked(params) {
      let routeData = this.$router.resolve({
        name: "Kullanıcı Detayı",
        params: {
          userId: params.node.data._id,
        },
      });
      window.open(routeData.href, "_blank");

      // const birthday = new Date(params.node.data.birthday);
      // var dd = String(birthday.getDate()).padStart(2, "0");
      // var mm = String(birthday.getMonth() + 1).padStart(2, "0"); //January is 0!
      // var yyyy = birthday.getFullYear();

      // this.userModalAddUpdate.process = "put";
      // this.userModalAddUpdate.title = "Üye Düzenle";

      // this.userModalAddUpdate.form._id = params.node.data._id;
      // this.userModalAddUpdate.form.whereDidYouHear =
      //   params.node.data.whereDidYouHear;
      // this.userModalAddUpdate.form.name = params.node.data?.name;
      // this.userModalAddUpdate.form.lastname = params.node.data.lastname;
      // this.userModalAddUpdate.form.email = params.node.data.email;
      // this.userModalAddUpdate.form.phone = params.node.data.phone;
      // this.userModalAddUpdate.form.birthday = dd + "" + mm + "" + yyyy;
      // this.userModalAddUpdate.form.defaultIdType =
      //   params.node.data.defaultIdType;
      // this.userModalAddUpdate.form.tcId = params.node.data.tcId;
      // this.userModalAddUpdate.form.passportId = params.node.data.passportId;

      // if (params.node.data.defaultIdType === 0) {
      //   this.userModalAddUpdate.form.tcId = params.node.data.tcId;
      //   this.userModalAddUpdate.form.passportId = "";
      // } else {
      //   this.userModalAddUpdate.form.tcId = "";
      //   this.userModalAddUpdate.form.passportId = params.node.data.passportId;
      // }
      // if (params.node.data.invoices) {
      //   this.userModalAddUpdate.form.invoices.invoiceType =
      //     params.node.data.invoices.invoiceType;

      //   this.userModalAddUpdate.form.invoices.individual.province =
      //     params.node.data.invoices.individual.province;
      //   this.userModalAddUpdate.form.invoices.individual.postCode =
      //     params.node.data.invoices.individual.postCode;
      //   this.userModalAddUpdate.form.invoices.individual.address =
      //     params.node.data.invoices.individual.address;

      //   this.userModalAddUpdate.form.invoices.individualCompany.firstname =
      //     params.node.data.invoices.individualCompany.firstname;
      //   this.userModalAddUpdate.form.invoices.individualCompany.lastname =
      //     params.node.data.invoices.individualCompany.lastname;
      //   this.userModalAddUpdate.form.invoices.individualCompany.taxOffice =
      //     params.node.data.invoices.individualCompany.taxOffice;
      //   this.userModalAddUpdate.form.invoices.individualCompany.taxNumber =
      //     params.node.data.invoices.individualCompany.taxNumber;
      //   this.userModalAddUpdate.form.invoices.individualCompany.province =
      //     params.node.data.invoices.individualCompany.province;
      //   this.userModalAddUpdate.form.invoices.individualCompany.postCode =
      //     params.node.data.invoices.individualCompany.postCode;
      //   this.userModalAddUpdate.form.invoices.individualCompany.address =
      //     params.node.data.invoices.individualCompany.address;

      //   this.userModalAddUpdate.form.invoices.company.title =
      //     params.node.data.invoices.company.title;
      //   this.userModalAddUpdate.form.invoices.company.taxOffice =
      //     params.node.data.invoices.company.taxOffice;
      //   this.userModalAddUpdate.form.invoices.company.taxNumber =
      //     params.node.data.invoices.company.taxNumber;
      //   this.userModalAddUpdate.form.invoices.company.province =
      //     params.node.data.invoices.company.province;
      //   this.userModalAddUpdate.form.invoices.company.postCode =
      //     params.node.data.invoices.company.postCode;
      //   this.userModalAddUpdate.form.invoices.company.address =
      //     params.node.data.invoices.company.address;

      //   this.userModalAddUpdate.form.invoices.abroad.title =
      //     params.node.data.invoices.abroad.title;
      //   this.userModalAddUpdate.form.invoices.abroad.firstname =
      //     params.node.data.invoices.abroad.firstname;
      //   this.userModalAddUpdate.form.invoices.abroad.lastname =
      //     params.node.data.invoices.abroad.lastname;
      //   this.userModalAddUpdate.form.invoices.abroad.passportNumber =
      //     params.node.data.invoices.abroad.passportNumber;
      //   this.userModalAddUpdate.form.invoices.abroad.taxNumber =
      //     params.node.data.invoices.abroad.taxNumber;
      //   this.userModalAddUpdate.form.invoices.abroad.country =
      //     params.node.data.invoices.abroad.country;
      //   this.userModalAddUpdate.form.invoices.abroad.province =
      //     params.node.data.invoices.abroad.province;
      //   this.userModalAddUpdate.form.invoices.abroad.postCode =
      //     params.node.data.invoices.abroad.postCode;
      //   this.userModalAddUpdate.form.invoices.abroad.address =
      //     params.node.data.invoices.abroad.address;
      // }

      // this.userModalAddUpdate.form.permissions = params.node.data.permissions;

      // this.userModalAddUpdate.modal = true;
      // this.getAgentNotesTotal(params.node.data._id);
      // this.couponsUserGet(params.node.data._id);
    },

    async getSettings() {
      let response = await axios.get(
        process.env.VUE_APP_API_URL + "admin/settings"
      );
      this.settings = response.data.settings;
    },

    async getUsers() {
      try {
        const query = this.searchUsersForm;
        this.$store.state.isLoading = true;
        let response = await axios.get(
          process.env.VUE_APP_API_URL + "admin/users",
          {
            params: query,
          }
        );

        let userList = response?.data?.userlist || [];

        this.$store.state.isLoading = false;
        userList.forEach((user) => {
          user.creatorName = user?.creator
            ? user.creator?.name + " " + user.creator.lastname
            : "";
          user.totalReservationPrice = 0;
          user.totalReservationRefundPrice = 0;
          user.totalReservationTL = 0;
          user?.totalReservation.map((pay) => {
            user.totalReservationTL += pay.prices.totalPrice.TRY;
            pay.payments.map((item) => {
              item.paymentType == "payment" &&
                (user.totalReservationPrice += item.totalAmount);
              item.paymentType == "refund" &&
                (user.totalReservationRefundPrice += item.totalAmount);
            });
          });
          function financial(x) {
            return Number.parseFloat(x).toFixed(2) + " TL";
          }

          user.totalTransaction = user.totalReservation.length;
          user.cancelledRez = user.totalReservation.filter(
            (reservation) => reservation.status === "cancelled"
          ).length;
          user.successRez = user.totalReservation.length - user.cancelledRez;

          user.totalReservationPrice = financial(user?.totalReservationPrice);
          user.totalReservationRefundPrice = financial(
            user.totalReservationRefundPrice
          );
        });
        this.rowData = userList;
        this.usersGridShow = true;
        this.searchBidCollapsed = false;
      } catch (error) {
        this.$store.state.isLoading = false;
        Vue.swal({
          icon: "error",
          title: "Hata",
          html: this.$t(error.response.data.message) || "Eksik Filtre",
          confirmButtonText: "Tamam",
        });
      }
    },

    async userAdd() {
      this.$Progress.start();
      this.userModalAddUpdate.btnDisable = true;
      const oldBirthday = this.userModalAddUpdate.form.birthday;
      var birthdaySplit = this.userModalAddUpdate.form.birthday.split(".");
      var birthday = new Date(
        Date.UTC(
          birthdaySplit[2],
          birthdaySplit[1] - 1,
          birthdaySplit[0],
          0,
          0,
          0
        )
      );

      this.userModalAddUpdate.form.birthday = birthday.toISOString();

      delete this.userModalAddUpdate.form._id;
      delete this.userModalAddUpdate.form.phoneInput;
      try {
        var result = await axios.post(
          process.env.VUE_APP_API_URL + "admin/user/add/create",
          this.userModalAddUpdate.form,
          {
            withCredentials: true,
          }
        );
        this.userModalAddUpdate.btnDisable = false;

        this.$Progress.finish();
        this.againUserAdd(
          this.userModalAddUpdate.form.email,
          this.userModalAddUpdate.form.phone
        );
        await this.getUsers(); // Verileri yeniden çek
      } catch (error) {
        this.userModalAddUpdate.form.birthday = oldBirthday.replace(/\D/g, "");

        this.userModalAddUpdate.btnDisable = false;
        this.$Progress.finish();
        Vue.swal.fire({
          icon: "error",
          showCloseButton: true,
          text: error.response.data.errors[0].message,
        });
      }
    },

    async userUpdate() {
      this.$Progress.start();
      this.userModalAddUpdate.btnDisable = true; // Modal'daki post b utton'u disable ettik.

      var birthdaySplit = this.userModalAddUpdate.form.birthday.split(".");

      var birthday = [];
      _.forEach(birthdaySplit, (o) => {
        birthday.push(o.padStart(2, "0"));
      });

      birthday = _.toString(_.reverse(birthday)).replaceAll(",", "-");

      this.userModalAddUpdate.form.birthday = birthday;
      try {
        await axios.put(
          process.env.VUE_APP_API_URL +
            "admin/users/" +
            this.userModalAddUpdate.form._id,
          {
            birthday: new Date(
              this.userModalAddUpdate.form.birthday
            ).toISOString(),
            lastname: this.userModalAddUpdate.form.lastname,
            name: this.userModalAddUpdate.form?.name,
            permissions: this.userModalAddUpdate.form.permissions,
            invoices: this.userModalAddUpdate.form.invoices,
            tcId: this.userModalAddUpdate.form.tcId || "",
            defaultIdType: this.userModalAddUpdate.form.defaultIdType || 0,
            passportId: this.userModalAddUpdate.form.passportId || "",
            whereDidYouHear: this.userModalAddUpdate.form.whereDidYouHear,
          },
          {
            withCredentials: true,
          }
        );

        this.successSwal("Üye başarılı bir şekilde güncellendi.");
        await this.getUsers(); // Verileri yeniden çek
      } catch (error) {
        this.userModalAddUpdate.btnDisable = false;
        this.$Progress.finish();
        Vue.swal.fire({
          icon: "error",
          showCloseButton: true,
          text: error.response.data.message || "Bir sorun oluştu!",
        });
      }
    },

    againUserAdd(email, phone) {
      Vue.swal
        .fire({
          icon: "warning",
          text: `Kullanıcının Cep telefonuna (${phone}) gönderilen SMS kodunu talep ediniz`,
          input: "text",
          inputAttributes: {
            autocapitalize: "off",
          },
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: "Gönder",
          denyButtonText: "Tekrar Kod Gönder",
          cancelButtonText: "Telefon No Değiştir",
          showLoaderOnConfirm: true,
          showCloseButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          preConfirm: async (code) => {
            var params = {
              code,
              email,
            };
            try {
              await axios.post(
                process.env.VUE_APP_API_URL + "admin/user/add/confirm",
                params,
                {
                  withCredentials: true,
                }
              );
            } catch (error) {
              Vue.swal.showValidationMessage(
                `Hata: ${error.response.data.message}`
              );
            }
          },
          preDeny: async () => {
            var params = {
              email,
            };
            try {
              await axios.post(
                process.env.VUE_APP_API_URL + "admin/user/add/resend",
                params,
                {
                  withCredentials: true,
                }
              );
            } catch (error) {
              Vue.swal.showValidationMessage(
                `Hata: ${error.response.data.message}`
              );
            }
          },
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.successSwal("Üye başarılı bir şekilde eklendi.");
          } else if (result.isDenied) {
            this.againUserAdd(email, phone);
          } else if (result.isDismissed) {
            this.updatePhoneUserCreat(email);
          }
        });
    },

    updatePhoneUserCreat(email) {
      Vue.swal
        .fire({
          icon: "warning",
          text: "Kullanıcının Cep telefonuna güncelleyiniz",
          input: "text",
          inputAttributes: {
            autocapitalize: "off",
          },
          showDenyButton: false,
          showCancelButton: true,
          confirmButtonText: "Gönder",
          cancelButtonText: "vazgeç",
          showLoaderOnConfirm: true,
          showCloseButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          preConfirm: async (phone) => {
            var params = {
              phone,
              email,
            };
            try {
              await axios.post(
                process.env.VUE_APP_API_URL + "admin/user/add/new-phone-resend",
                params,
                {
                  withCredentials: true,
                }
              );
            } catch (error) {
              Vue.swal.showValidationMessage(
                `Hata: ${error.response.data.message}`
              );
            }
          },
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.againUserAdd(email, result.value);
          } else if (result.isDismissed) {
            this.againUserAdd(email);
          }
        });
    },

    successSwal(text, refresh = true) {
      let timerInterval;
      Vue.swal
        .fire({
          icon: "success",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          text,
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false,
          willClose: () => {
            clearInterval(timerInterval);
          },
        })
        .then((result) => {
          if (result.dismiss === Vue.swal.DismissReason.timer) {
            if (refresh) {
              this.getUsers(); // Verileri yeniden çek
            }
          }
        });
    },

    async getAgentNotesTotal(id) {
      try {
        let agentNotes = await axios.get(
          process.env.VUE_APP_API_URL + "admin/note?place._id=" + id,
          {
            withCredentials: true,
          }
        );
        this.agentNotesTotal = agentNotes.data.length;
      } catch (error) {}
    },
    clearSearchForm() {
      this.searchUsersForm = {
        status: [],
        name: "",
        lastname: "",
        email: "",
        phone: "",
        birthday: [],
        createdDate: [],
        identityId: "",
        permissions: [],
      };
    },
  },
};
</script>

<style lang="scss"></style>
